@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  margin: 0 auto;
}

.Minter {
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #254cdd;
  color: #254cdd;
  background-color: white;
  border-radius: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

#title {
  padding-top: 32px;
}

#status {
  color:red;
}

#sorry {
  color:red;
  margin-top: 10px;
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  line-height: 16px;
}

#mintButton {
}
